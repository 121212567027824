import { COMMUNITY_USER_BASE_FRAGMENT } from '@/graphql/_Fragments/CommunityUser/Base';
import { MESSAGE_GROUP_BASE_FRAGMENT } from '@/graphql/_Fragments/MessageGroup/Base';

export const MESSAGE_FULL_FRAGMENT = `
  fragment messageFullFragment on Message {
    uid
    schemaCode
    type
    content
    userDeleted
    senttime
    senttimestamp
    user {
      ...communityUserBaseFragment
    }
    group {
      ...messageGroupBaseFragment
    }
    childMessages(filter: { userDeleted_not: true }) {
      uid
      schemaCode
      type
      content
      senttime
      senttimestamp
      userDeleted
      user {
        ...communityUserBaseFragment
      }
    }
  }
  ${COMMUNITY_USER_BASE_FRAGMENT}
  ${MESSAGE_GROUP_BASE_FRAGMENT}
`;
